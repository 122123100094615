@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/inter/regular.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/inter/medium.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/inter/bold.woff2") format("woff2");
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: var(--chakra-colors-gray-10);
}

#app {
  width: 100%;
  height: 100%;
  background: var(--chakra-colors-gray-10);
}

.chakra-table tbody tr:last-child td {
  border-bottom: 0;
}

.react-select-small div {
  border-radius: 4px;
}

.react-select-small > div > div:first-of-type > div:first-child {
  padding: 0 10px;
}

.react-select-small > div > div:first-of-type > div:nth-child(2) > div {
  padding: 0 12px;
}

.react-select-small > div > div {
  min-height: 32px;
}

ol, ul {
  list-style-position: inside;
  margin-inline-start: 0.5em;
  margin-left: 0.5em;
}